export async function getDisplayStream(){
  var videoConstraints = {};
  videoConstraints.frameRate = 30;
  videoConstraints.width = 1280;
  videoConstraints.height = 720;
  var displayMediaStreamConstraints = {
    video: videoConstraints
  };
  return navigator.mediaDevices.getDisplayMedia(displayMediaStreamConstraints);
}

export async function getAudioStream(){
    return navigator.mediaDevices.getUserMedia({ video:undefined, audio: true });
}

export async function getVideoStream(){
	const video = {
      width: { min: 160, ideal: 640, max: 1280 },
      height: { min: 120, ideal: 360, max: 720 },
      facingMode: 'user'
    }
    return navigator.mediaDevices.getUserMedia({ video:video, audio: undefined });
}

export async function getAllStream(){
  const audio = {
    echoCancellation: true,
    noiseSuppression: true,
    googEchoCancellation: true,
    googEchoCancellation2: true,
    googDAEchoCancellation: true,
    googTypingNoiseDetection: true
  }
	const video = {
    width: { min: 160, ideal: 640, max: 960 },
    height: { min: 120, ideal: 480, max: 720 },
    frameRate: 30,
    facingMode: 'user'
  }
    return navigator.mediaDevices.getUserMedia({ video:video, audio: audio });
}