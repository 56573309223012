import Peer from "simple-peer";

const userAgent = window.navigator.userAgent;
export default class VideoCall {
  peer = null;
  allowP2P = true;
  turn1 = process.env.REACT_APP_TURN_SERVERS.split(",");
  turn2 = process.env.REACT_APP_TURN_SERVERS2.split(",");

  init = (stream, initiator, allowP2P) => {
    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/Macintosh/i)
    ) {
      this.turn1 = this.turn1.map(function (item, i) {
        return item.replace("turns", "turn");
      });
      this.turn2 = this.turn2.map(function (item, i) {
        return item.replace("turns", "turn");
      });
    }
    this.allowP2P = allowP2P;
    this.peer = new Peer({
      initiator: initiator,
      channelName: "WebRTCData",
      sdpSemantics: "unified-plan",
      stream: stream,
      trickle: true,
      reconnectTimer: 1000,
      offerConstraints: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      },
      answerOptions: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      },
      config: {
        iceTransportPolicy: this.allowP2P ? "all" : "relay",
        iceServers: [
          { urls: process.env.REACT_APP_STUN_SERVERS.split(",") },
          {
            urls: this.turn1,
            username: process.env.REACT_APP_TURN_USERNAME,
            credential: process.env.REACT_APP_TURN_CREDENCIAL,
          },
          {
            urls: this.turn2,
            username: process.env.REACT_APP_TURN_USERNAME,
            credential: process.env.REACT_APP_TURN_CREDENCIAL,
          },
        ],
      },
    });
    return this.peer;
  };
}
