import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css'
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

// Sentry.init({
// 	dsn: "https://df66b789d8f24ffd80a0be4cefc9a7b0@sentry.drjaysayhi.com/12",
// 	debug: true,
// 	integrations: [
// 	    new CaptureConsole({
// 	      levels: ['error', 'warn', 'info', 'debug', 'console']
// 	    })
// 	  ],
// });

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
