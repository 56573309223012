import React from "react";
import { Label } from "semantic-ui-react";

export default class VideoItem extends React.PureComponent {
  videoRef = React.createRef();

  componentDidMount() {
    this.componentDidUpdate();
  }
  componentDidUpdate() {
    const { stream } = this.props;
    const video = this.videoRef.current;

    if ("srcObject" in video) {
      video.srcObject = stream;
    } else {
      video.src = window.URL.createObjectURL(stream); // for older browsers
    }
  }

  render() {
    const { userId, style, muted, controls } = this.props;
    // Get the position of the video element
    const video = this.videoRef.current;
    const videoHeight = video ? video.videoHeight : 0;
    const videoWidth = video ? video.offsetWidth : 0;
    const videoRect = video ? video.getBoundingClientRect() : { x: 0, y: 0 };
    const parentRect = video ? video.parentElement.getBoundingClientRect() : { x: 0, y: 0 };
    // Calculate the position of the label based on the parent's dimensions and the video's dimensions
    const x = videoRect.x
    const y = videoRect.y 
    // console.log(parentRect);
    // console.log(video)

    return (
      <>
        <video
          id={`video-${userId}`}
          autoPlay
          controls={controls}
          playsInline
          crossOrigin="true"
          muted={muted}
          ref={this.videoRef}
          style={style}
        />
        {/* <Label
          as="a"
          color="black"
          style={{ position: "absolute", left: x, top: 0  }}
        >
          {userId}
        </Label> */}
      </>
    );
  }
}
