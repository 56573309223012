import React, { useState } from 'react';
import { Modal, Rating, Button } from 'semantic-ui-react';

const RatingPopup = ({ open, onClose }) => {
  const [rating, setRating] = useState(0);

  const handleRatingChange = (e, { rating }) => {
    setRating(rating);
  };

  const handleRateButtonClick = () => {
    // Handle the rating submission logic here
    console.log(`User rated the call quality as: ${rating}`);
    // You can send this rating to your server or perform any other actions
    onClose(); // Close the popup
  };

  return (
    <Modal open={open} onClose={onClose} size="tiny">
      <Modal.Header>Rate Call Quality</Modal.Header>
      <Modal.Content>
        <p>Please rate the call quality:</p>
        <Rating icon="star" rating={rating} maxRating={5} onRate={handleRatingChange} />
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={handleRateButtonClick}>
          Submit Rating
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RatingPopup;